<template>
  <div class="oswald-font padding-top">
    <span class="font-menu" @click="openPopup"
      >About Café
      <img
        alt="cafe chords logo small"
        class="small-logo"
        src="./assets/chords-logo-small.png"
    /></span>
  </div>
  <div class="h-full logo-section">
    <img
      alt="Cafe Chords logo"
      src="./assets/chords-logo.png"
      class="logo-class"
    />
    <div class="oswald-font" style="padding-right: 2rem;
    padding-left: 2rem; text-align: center; margin-top:10px;">
      <div style="display: flex; justify-content: center; align-items: center; width: 100%; flex-wrap: wrap;">
        <a href="https://www.facebook.com/profile.php?id=61556272806184" target="_blank" style="text-decoration: none; color: black">
          <div
            style="
              display: flex;
              border: 1px solid rgb(192, 192, 192);
              border-radius: 50px;
              padding: 10px 15px 10px 15px;
              margin: 5px;
            "
          >
            <img src="./assets/facebook.png" style="width: 30px" />&nbsp;&nbsp;&nbsp;
            <span style="margin: auto;"> Facebook</span>
          </div>
        </a>
        <a href="https://www.instagram.com/cafe.chords/" target="_blank" style="text-decoration: none; color: black">
          <div
            style="
              display: flex;
              border: 1px solid rgb(192, 192, 192);
              border-radius: 50px;
              padding: 10px 15px 10px 15px;
              margin: 5px;
            "
          >
            <img src="./assets/instagram.png" style="width: 30px" />&nbsp;&nbsp;&nbsp;
            <span style="margin:auto;"> Instagram</span>
          </div>
        </a>
        <a href="https://maps.app.goo.gl/v5J2irzAxss9x4Zy7" target="_blank" style="text-decoration: none; color: black">
          <div
            style="
              display: flex;
              border: 1px solid rgb(192, 192, 192);
              border-radius: 50px;
              padding: 10px 15px 10px 15px;
              margin: 5px;
            "
          >
            <img src="./assets/maps.png" style="width: 30px" />&nbsp;&nbsp;&nbsp;
            <span style="margin:auto;"> Google Maps</span>
          </div>
        </a>
        <a href="tel:+923394033941" style="text-decoration: none; color: black">
          <div
            style="
              display: flex;
              border: 1px solid rgb(192, 192, 192);
              border-radius: 50px;
              padding: 10px 15px 10px 15px;
              margin: 5px;
            "
          >
            <img src="./assets/phone.png" style="width: 30px" />&nbsp;&nbsp;&nbsp;
            <span style="margin:auto;"> Phone</span>
          </div>
        </a>
        <a href="mailto:contact@cafechords.com" style="text-decoration: none; color: black">
          <div
            style="
              display: flex;
              border: 1px solid rgb(192, 192, 192);
              border-radius: 50px;
              padding: 10px 15px 10px 15px;
              margin: 5px;
            "
          >
            <img src="./assets/mail.png" style="width: 30px" />&nbsp;&nbsp;&nbsp;
            <span style="margin:auto;"> Email</span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <VueCountdown
    :time="totalTime"
    v-slot="{ days, hours, minutes, seconds }"
    :class="{ 'fade-in': !loading }"
    class="timer h-full"
  >
    <h1 class="text-xl oswald-font" style="margin: 0.5rem;">
      {{ days }}:{{ hours }}:{{ minutes }}:{{ seconds }}
    </h1>
  </VueCountdown>
  <small class="oswald-font text-muted">Because of unfortunate road conditions in islamabad, Opening is delayed till 2nd November 2024 at 12pm</small>

  <!-- Full Screen Popup -->
  <div
    v-show="showPopup"
    :class="{ 'fade-in-popup': showPopup }"
    class="fullscreen-popup oswald-font"
  >
    <span class="close-icon" @click="closePopup">&times;</span>
    <div class="popup-content">
      <h1 style="color: #770200">About Café Chords Islamabad</h1>
      <p>
        Located in the vibrant neighborhood of Gulberg Greens, Islamabad, Cafe
        Chords is more than just a coffee shop – it's a creative hub for
        musicians, comedians, artists, and coffee lovers alike. Designed to
        inspire and foster artistic expression, our cafe offers a welcoming
        space for live performances, open mic nights, and artistic
        collaboration. Whether you're looking to enjoy a cup of expertly brewed
        artisanal coffee or showcase your talent on stage, Cafe Chords provides
        the perfect atmosphere for creativity. From soothing acoustic sessions
        to stand-up comedy, our cafe is where artists and enthusiasts come
        together to experience and celebrate local talent. At Cafe Chords, we’re
        committed to delivering not only the best coffee but also unforgettable
        artistic experiences, making us a go-to destination in Islamabad for
        those seeking inspiration, entertainment, and community.
      </p>
      <hr style="margin-top: 2rem; margin-bottom: 2rem" />
      <div
        style="
          margin-bottom: 20px;
          border: 1px solid rgb(192, 192, 192);
          border-radius: 15px;
          padding: 10px 25px 10px 25px;
          text-align: left;
        "
      >
        <h2>Calling All Musicians, Comedians, and Artists in Islamabad!</h2>
        <p style="margin-top: 0.5rem">
          Cafe Chords is the perfect place in Gulberg Greens for artists,
          musicians, and comedians to gather, perform, and enjoy premium coffee.
          Our cafe is designed to inspire creativity and foster a welcoming
          environment for all kinds of artists.
        </p>
      </div>
      <div
        style="
          margin-bottom: 20px;
          border: 1px solid rgb(192, 192, 192);
          border-radius: 15px;
          padding: 10px 25px 10px 25px;
          text-align: left;
        "
      >
        <h2>Live Music & Open Mic Nights at Cafe Chords</h2>
        <p style="margin-top: 0.5rem">
          Experience live performances by local talent or showcase your own
          skills during our weekly open mic nights in the heart of Islamabad.
          Whether you’re a musician, comedian, or poet, our stage is open to
          you!
        </p>
      </div>
      <div
        style="
          margin-bottom: 20px;
          border: 1px solid rgb(192, 192, 192);
          border-radius: 15px;
          padding: 10px 25px 10px 25px;
          text-align: left;
        "
      >
        <h2>Artisanal Coffee for Creative Minds</h2>
        <p style="margin-top: 0.5rem">
          Our expertly crafted coffee blends are perfect for fueling your
          creativity. Enjoy your favorite brew while taking in live performances
          or collaborating with fellow artists in a creative cafe environment at
          Cafe Chords, Islamabad.
        </p>
      </div>
      <hr style="margin-top: 2rem; margin-bottom: 2rem" />
      <h4 style="margin:0;">Join Us At <span style="font-weight: 400;">Cafe Chords, Shop # 26, Lexus Mall, Block B, Gulberg Greens, Islamaabd</span></h4>
      <h4 style="margin:0;">Tel <span style="font-weight: 400;">+92 339 4033941</span></h4>
      
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  name: "App",
  components: {
    VueCountdown,
  },
  data() {
    return {
      loading: true,
      showPopup: false,
    };
  },
  computed: {
    display() {
      return this.loading ? "none" : "block";
    },
    totalTime() {
      const today = new Date();
      const targetYear = 2024;
      const targetDate = new Date(targetYear, 10, 2, 18, 0, 0);
      const diffInMs = targetDate - today;
      return diffInMs;
    },
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
  },
  mounted() {
    this.loading = false;
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

.oswald-font {
  font-family: "Oswald", sans-serif;
}

#app {
  width: 100%;
  height: 97vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text-xl {
  font-size: 4.5rem;
  margin-bottom: 0 !important;
  font-weight: 400;
}

.logo-class {
  width: 100%;
  max-width: 40vw;
  height: auto;
}

@media only screen and (max-width: 600px) {
  .logo-class {
    max-width: 80vw;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .logo-class {
    max-width: 50vw;
  }
}

@media only screen and (min-width: 1025px) {
  .logo-class {
    max-width: 40vw;
  }
}

.timer {
  display: none;
  opacity: 0;
  font-weight: 400 !important;
  transition: opacity 3s ease-in-out;
}

.fade-in {
  display: block;
  opacity: 1;
  transition: opacity 3s ease-in-out;
}

.small-logo {
  width: 3.5rem;
}

.font-menu {
  font-size: 1.3rem;
  cursor: pointer;
}

/* Full Screen Popup Styles */
.fullscreen-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.popup-content {
  background-color: white;
  padding: 2rem;
  text-align: center;
  position: relative;
  max-width: 60%;
  border-radius: 10px;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 900px) {
  .popup-content {
    background-color: white;
    padding: 2rem;
    text-align: center;
    position: relative;
    max-width: 80%;
    border-radius: 10px;
  }
}

.close-icon {
  position: fixed;
  top: 0;
  right: 1rem;
  font-size: 4rem;
  font-weight: 400;
  cursor: pointer;
  color: #333;
  z-index: 9999;
}

.fade-in-popup {
  opacity: 1;
}
.padding-top {
  padding: 2rem;
}
.logo-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text-muted{
  color:rgb(134, 133, 133);
  text-align: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
</style>
